import * as React from 'react'

import Page from '../components/Page'
import Container from '../components/Container'
import IndexLayout from '../layouts'
import { css } from '@emotion/core'
import ropLogo from './images/roots-of-pacha-logo.png'
import sodaDenLogo from './images/soda-den-logo.png'

const centeredContainerCss = css`
  position: absolute;
  display: flex;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  justify-content: center;
  font-family: 'Londrina Solid';
  color: #fff;
  /* align-items: center; */

`

const outerContainerCss = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  padding-top: 36px;
  width: 100%;
  max-width: 500px;
`;

const containerCss = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 1.5rem;
  /* background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(2px); */
  width: 100%;
  padding: 0px 16px;
  border-radius: 20px;
  /* text-shadow: 2px 2px rgba(0,0,0,0.5); */

`;



const sodaDenDescCss = css`
  background: #d6714a;
  color: #fff;
  padding: 16px 32px;
  border-radius: 16px;
`

const sodaDenLogoCss = css`
  max-width: 300px !important;
`;

const ropContainerCss = css`
  padding-top: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 1.2rem;
  padding: 16px;
  border-radius: 16px;
  width: 100%;
  background: #fff;
`;

const ropDescCss = css`
  font-size: 2rem;
  color: #75232a;
`

const ropLogoCss = css`
  max-width: 400px !important;
`;


const IndexPage = () => (
  <IndexLayout>
    <Page>
      <Container css={[centeredContainerCss]}>
        <div css={[outerContainerCss]}>
          <div css={[containerCss]}>
            <img css={[sodaDenLogoCss]} src={sodaDenLogo}></img>
            <p css={sodaDenDescCss}>A game studio by a pair of brothers.</p>
          </div>
          <div css={[containerCss, css`margin-top: 16px;`]}>
            <div css={[ropContainerCss]}>
              <p css={ropDescCss}>Currently developing</p>
              <a href={"https://rootsofpacha.com"}><img css={[ropLogoCss]} src={ropLogo}></img></a>
            </div>
          </div>
        </div>
      </Container>



    </Page>
  </IndexLayout>
)

export default IndexPage
